<template>
  <div class="row">
    <div class="col-md-8">
      <Print
        :data="data"
        height="1000px"
        :setting="setting"
        :patient="patient"
      />
      <!-- <Print
        :data="data"
        height="800px"
        :setting="setting"
      /> -->
    </div>

    <!-- PDF -->
    <!-- <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="`test-laboratorium-${data.patient_name}`"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <Print
          :data="data"
          height="470px"
          :setting="setting"
        />
      </section>
    </vue-html2pdf> -->

    <div class="col-md">
      <b-button
        squared
        class="mt-2"
        size="sm"
        variant="primary"
        @click="btnPrintOnClick"
        >Print</b-button
      >
      <!-- <b-button
        squared
        :class="'d-block mt-2'"
        size="sm"
        variant="info"
        @click="btnDownloadOnClick"
      >Download</b-button> -->
      <b-button
        squared
        :class="'d-block mt-2'"
        size="sm"
        variant="success"
        v-if="manipulateBtn == true"
        @click="$router.push('/medical-checkup/edit/' + data.id)"
        >Edit MCU</b-button
      >
      <b-button
        squared
        :class="'d-block mt-2'"
        size="sm"
        variant="success"
        v-if="manipulateBtn == true"
        @click="$router.push('/medical-checkup/add')"
        >Tambah Baru</b-button
      >
    </div>
  </div>
</template>

<script>
import Print from "@/component/medical-checkup/Print.vue";
import module from "@/core/modules/CrudModule.js";
import VueHtml2pdf from "vue-html2pdf";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    Print,
    VueHtml2pdf,
  },

  data() {
    return {
      // Data
      data: {},
      setting: {},
      patient: {},
      manipulateBtn: false,
    };
  },

  methods: {
    async getSetting() {
      this.setting = await module.get(`settings/${1}`);
    },
    async getPatient() {
      this.patient = await module.get(`patients/${this.data.patient_id}`);
    },

    async get() {
      this.data = await module.get(`medical-checkup/${this.$route.params.id}`);
      this.getPatient();
      // If Data Not found
      if (this.data == null) {
        // Redirect To List
        this.$router.push("medical-checkup/list");
      }
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        ;

        if (access_right[a] == "1109") {
          this.manipulateBtn = true;
        }
      }
    },
    onProgress($event) {
      console.log("onProgress", $event);
    },

    hasStartedGeneration() {
      console.log("hasStartedGeneration");
    },

    hasGenerated($event) {
      console.log("hasGenerated", $event);
    },

    btnPrintOnClick() {
      this.$htmlToPaper("print-medical-checkup");
    },

    btnDownloadOnClick() {
      this.$refs.html2Pdf.generatePdf();
    },

    // access management
    // async setActiveMenu() {

    //   let access_right_user = window.localStorage.getItem("access_right_display")
    //   let access_right = JSON.parse(access_right_user)

    //   let a
    //   for (a = 0; a < access_right.length; a++) {
    //     

    //     if (access_right[a] == "1102") {
    //       this.manipulateBtn = true
    //     }

    //   }
    // },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Medical Check Up" },
      { title: "Detail Medical Check Up" },
    ]);
    // Get Data
    this.get();

    this.getSetting();
    this.setActiveMenu();
  },
};
</script>

<style></style>
