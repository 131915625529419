<template>
  <div class="card" style="border: none" id="print-medical-checkup">
    <div class="card-body">
      <div class="row">
        <div class="col-md-4 text-center">
          <ImageComponent place="logo-invoice"></ImageComponent>
        </div>
        <div class="col-md-8">
          <div class="row justify-content-center">
             <div class="col-md-12 pb-0">
              <h4 class="font-weight-bold" style="font-size: 18pt">
                {{ setting.clinic_name }}
              </h4>
            </div>
            <div class="col-md-12 pb-0">
              <h6 class="font-weight-light" style="font-size: 11pt">
                {{ setting.clinic_address }}, Telp: {{ setting.phone }}
              </h6>
            </div>
           
          </div>
        </div>

        <div class="col-md-12 py-3 my-1" style=" border-top: 1px solid black; margin-top: 10px !important;">
        </div>
      </div>
      <div class="text-center mt-9">
        <h4
          style="font-weight: bold; text-transform: uppercase; font-size: 16px"
        >
          Hasil Medical Check Up
        </h4>
      </div>
      <div class="d-flex justify-content-between mt-5" style="font-size: 12px">
        <div class="col-md-6 p-0">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px" class="def-padding">Nama Pasien </td>
              <td>: {{ data.patient_name }}</td>
            </tr>
            <tr>
              <td style="width: 180px; padding: 1.3px" class="def-padding">Jenis Kelamin</td>
              <td>: {{ patient.gender }}</td>
            </tr>
            <tr>
              <td style="width: 180px; padding: 1.3px" class="def-padding">Tanggal Lahir (umur)</td>
              <td>: {{ patient.display_birt_date + " (" + patient.age + ")" }}</td>
            </tr>
            <tr>
              <td style="width: 180px; padding: 1.3px" class="def-padding">Alamat</td>
              <td>: {{ patient.address}}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-6 p-0">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px" class="def-padding">Nama Dokter</td>
              <td>: {{ data.doctor_name }}</td>
            </tr>
            <tr>
              <td style="width: 180px; padding: 1.3px" class="def-padding">Tanggal MCU</td>
              <td>: {{ data.display_mcu_date }}</td>
            </tr>
            <tr>
              <td style="width: 180px; padding: 1.3px" class="def-padding">Nomor Formulir</td>
              <td>: {{ data.mcu_number }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-12">
          <span style="font-size: 16px;" class="font-weight-bold d-block mb-2">Pemeriksaan Fisik</span>
          <span class="font-weight-bold d-block" >1. kondisi Fisik</span>
        </div>
       
        <div class="col-md-6">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px" class="def-padding">Berat Badan</td>
              <td>: {{ data.weight +" Kg" }}</td>
            </tr>
            <tr>
              <td style="width: 180px; padding: 1.3px" class="def-padding">Tinggi Badan</td>
              <td>: {{ data.height + " cm"}}</td>
            </tr>
            <tr>
              <td style="width: 180px; padding: 1.3px" class="def-padding">Index Massa Tubuh (BMI)</td>
              <td>: {{ data.bmi }}</td>
            </tr>
            <tr>
              <td style="width: 180px; padding: 1.3px" class="def-padding">Suhu</td>
              <td>: {{ data.temperature}} &deg;C</td>
            </tr>
          </table>
        </div>
        <div class="col-md-6 p-0">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px" class="def-padding">Tekanan Darah</td>
              <td>: {{ data.blood_pressure +" mmHg" }}</td>
            </tr>
            <tr>
              <td style="width: 180px; padding: 1.3px" class="def-padding">Denyut Nadi</td>
              <td>: {{ data.pulse + " x/Menit"}}</td>
            </tr>
            <tr>
              <td style="width: 180px; padding: 1.3px" class="def-padding">Frekuensi Napas</td>
              <td>: {{ data.respiratory_rate + " x/Menit" }}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-12">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px" class="def-padding">Catatan/Lainnya</td>
              <td>: {{ data.physical_status_notes}}</td>
            </tr>
          </table>
        </div>
      </div>

      <!-- health history -->
      <div class="row mt-2">
        <div class="col-md-12">
          <span class="font-weight-bold d-block" >2. Riwayat Kesehatan</span>
        </div>
       
        <div class="col-md-6 def-padding" v-for="(value, name, index) in data.health_history" :key="index">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">{{health_history_label[name]}}</td>
              <td>: {{ value == 0 ? "Tidak" : "Ya" }}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-12">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">Catatan/Lainnya</td>
              <td>: {{ data.health_history_notes }}</td>
            </tr>
          </table>
        </div>
      </div>
      <!-- end health history -->
     
      <!-- family health history -->
      <div class="row mt-2">
        <div class="col-md-12">
          <span class="font-weight-bold d-block" >3. Riwayat Kesehatan Keluarga</span>
        </div>
       
        <div class="col-md-6 def-padding" v-for="(value, name, index) in data.family_health_history" :key="index">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">{{family_health_history_label[name]}}</td>
              <td>: {{ value == 0 ? "Tidak" : "Ya" }}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-12">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">Catatan/Lainnya</td>
              <td>: {{ data.family_health_history_notes }}</td>
            </tr>
          </table>
        </div>
      </div>
      <!-- end family health history -->
     
      <!-- working history -->
      <div class="row mt-2">
        <div class="col-md-12">
          <span class="font-weight-bold d-block" >3. Riwayat Lingkungan Kerja</span>
        </div>
       
        <div class="col-md-6 def-padding" v-for="(value, name, index) in data.working_history" :key="index">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">{{working_history_label[name]}}</td>
              <td>: {{ value == 0 ? "Tidak" : "Ya" }}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-12">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">Catatan/Lainnya</td>
              <td>: {{ data.work_history_notes }}</td>
            </tr>
          </table>
        </div>
      </div>
      <!-- end working history -->

      <!-- habit history -->
      <div class="row mt-2">
        <div class="col-md-12">
          <span class="font-weight-bold d-block" >3. Riwayat Kebiasaan</span>
        </div>
       
        <div class="col-md-6 def-padding" v-for="(value, name, index) in data.habits" :key="index">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">{{habits_label[name]}}</td>
              <td>: {{ value == 0 ? "Tidak" : "Ya" }}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-12">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">Catatan/Lainnya</td>
              <td>: {{ data.habits_notes }}</td>
            </tr>
          </table>
        </div>
      </div>
      <!-- end habit history -->
     
      <!-- head -->
      <div class="row mt-2">
         <div class="col-md-12">
          <span style="font-size: 16px;" class="font-weight-bold d-block mb-2">Status Fisik</span>
          <span class="font-weight-bold d-block" >1. Kepala</span>
        </div>
       
        <div class="col-md-6 def-padding" v-for="(value, name, index) in data.head" :key="index">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">{{head_label[name]}}</td>
              <td>: {{ value == 0 ? "Normal" : "Tidak Normal" }}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-12">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">Catatan/Lainnya</td>
              <td>: {{ data.head_notes }}</td>
            </tr>
          </table>
        </div>
      </div>
      <!-- end head -->
     
      <!-- eyes -->
      <div class="row mt-2">
         <div class="col-md-12">
          <span class="font-weight-bold d-block" >2. Mata</span>
        </div>
       
        <div class="col-md-6 def-padding">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">Visus Kanan</td>
              <td>: {{ data.eyes.visus_right }}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-6 def-padding">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">Visus Kiri</td>
              <td>: {{ data.eyes.visus_left }}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-6 def-padding">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">Menggunakan Kaca Mata</td>
              <td>: {{ data.eyes.is_glasses == 0 ? "Tidak" : "Ya"}}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-6 def-padding">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">Strabismus</td>
              <td>: {{ data.eyes.strabismus == 0 ? "Normal" : "Tidak Normal"}}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-6 def-padding">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">Steve & Conjungtiva</td>
              <td>: {{ data.eyes.steve_conjungtiva == 0 ? "Normal" : "Tidak Normal"}}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-6 def-padding">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">Buta Warna</td>
              <td>: {{ data.eyes.color_blind == 0 ? "Tidak" : "Ya"}}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-6 def-padding">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">Reflek Pupil</td>
              <td>: {{ data.eyes.pupillary_reflex == 0 ? "Normal" : "Tidak Normal"}}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-12">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">Catatan/Lainnya</td>
              <td>: {{ data.eyes_notes }}</td>
            </tr>
          </table>
        </div>
      </div>
      <!-- end eyes -->

      <!-- ears -->
      <div class="row mt-2">
         <div class="col-md-12">
          <span class="font-weight-bold d-block" >3. Telinga</span>
        </div>
       
        <div class="col-md-6 def-padding" v-for="(value, name, index) in data.ears" :key="index">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">{{ears_label[name]}}</td>
              <td>: {{ value == 0 ? "Normal" : "Tidak Normal" }}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-12">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">Catatan/Lainnya</td>
              <td>: {{ data.ears_notes }}</td>
            </tr>
          </table>
        </div>
      </div>
      <!-- end ears -->
      
      <!-- nose -->
      <div class="row mt-2">
         <div class="col-md-12">
          <span class="font-weight-bold d-block" >4. Hidung</span>
        </div>
       
        <div class="col-md-6 def-padding" v-for="(value, name, index) in data.nose" :key="index">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">{{nose_label[name]}}</td>
              <td>: {{ value == 0 ? "Normal" : "Tidak Normal" }}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-12">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">Catatan/Lainnya</td>
              <td>: {{ data.nose_notes }}</td>
            </tr>
          </table>
        </div>
      </div>
      <!-- end nose -->

      <!-- throat -->
      <div class="row mt-2">
         <div class="col-md-12">
          <span class="font-weight-bold d-block" >5. Tenggorokan</span>
        </div>
       
        <div class="col-md-6 def-padding" v-for="(value, name, index) in data.throat" :key="index">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">{{throat_label[name]}}</td>
              <td>: {{ value == 0 ? "Normal" : "Tidak Normal" }}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-12">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">Catatan/Lainnya</td>
              <td>: {{ data.throat_notes }}</td>
            </tr>
          </table>
        </div>
      </div>
      <!-- end throat -->
     
      <!-- mounth_teeth -->
      <div class="row mt-2">
         <div class="col-md-12">
          <span class="font-weight-bold d-block" >6. Mulut & Gigi</span>
        </div>
       
        <div class="col-md-6 def-padding" v-for="(value, name, index) in data.mounth_teeth" :key="index">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">{{mounth_teeth_label[name]}}</td>
              <td>: {{ value == 0 ? "Normal" : "Tidak Normal" }}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-12">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">Catatan/Lainnya</td>
              <td>: {{ data.mounth_teeth_notes }}</td>
            </tr>
          </table>
        </div>
      </div>
      <!-- end mounth_teeth -->

       <!-- neck -->
      <div class="row mt-2">
         <div class="col-md-12">
          <span class="font-weight-bold d-block" >7. Leher</span>
        </div>
       
        <div class="col-md-6 def-padding" v-for="(value, name, index) in data.neck" :key="index">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">{{neck_label[name]}}</td>
              <td>: {{ value == 0 ? "Normal" : "Tidak Normal" }}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-12">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">Catatan/Lainnya</td>
              <td>: {{ data.neck_notes }}</td>
            </tr>
          </table>
        </div>
      </div>
      <!-- end neck -->

       <!-- thorax -->
      <div class="row mt-2">
         <div class="col-md-12">
          <span class="font-weight-bold d-block" >8. Thorax</span>
        </div>
       
        <div class="col-md-6 def-padding" v-for="(value, name, index) in data.thorax" :key="index">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">{{thorax_label[name]}}</td>
              <td>: {{ value == 0 ? "Normal" : "Tidak Normal" }}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-12">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">Catatan/Lainnya</td>
              <td>: {{ data.thorax_notes }}</td>
            </tr>
          </table>
        </div>
      </div>
      <!-- end thorax -->
      
       <!-- abdomen -->
      <div class="row mt-2">
         <div class="col-md-12">
          <span class="font-weight-bold d-block" >9. Abdomen</span>
        </div>
       
        <div class="col-md-6 def-padding" v-for="(value, name, index) in data.abdomen" :key="index">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">{{abdomen_label[name]}}</td>
              <td>: {{ value == 0 ? "Normal" : "Tidak Normal" }}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-12">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">Catatan/Lainnya</td>
              <td>: {{ data.abdomen_notes }}</td>
            </tr>
          </table>
        </div>
      </div>
      <!-- end abdomen -->
      
       <!-- extremitas -->
      <div class="row mt-2">
         <div class="col-md-12">
          <span class="font-weight-bold d-block" >10. Extremitas</span>
        </div>
       
        <div class="col-md-6 def-padding" v-for="(value, name, index) in data.extremitas" :key="index">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">{{extremitas_label[name]}}</td>
              <td>: {{ value == 0 ? "Normal" : "Tidak Normal" }}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-12">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">Catatan/Lainnya</td>
              <td>: {{ data.extremitas_notes }}</td>
            </tr>
          </table>
        </div>
      </div>
      <!-- end extremitas -->
      
       <!-- reflex -->
      <div class="row mt-2">
         <div class="col-md-12">
          <span class="font-weight-bold d-block" >11. Reflex</span>
        </div>
       
        <div class="col-md-6 def-padding" v-for="(value, name, index) in data.reflex" :key="index">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">{{reflex_label[name]}}</td>
              <td>: {{ value == 0 ? "Normal" : "Tidak Normal" }}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-12">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">Catatan/Lainnya</td>
              <td>: {{ data.reflex_notes }}</td>
            </tr>
          </table>
        </div>
      </div>
      <!-- end reflex -->
      
       <!-- coordination -->
      <div class="row mt-2">
         <div class="col-md-12">
          <span class="font-weight-bold d-block" >12. Koordinasi Otot</span>
        </div>
       
        <div class="col-md-6 def-padding" v-for="(value, name, index) in data.coordination" :key="index">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">{{coordination_label[name]}}</td>
              <td>: {{ value == 0 ? "Normal" : "Tidak Normal" }}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-12">
          <table>
            <tr>
              <td style="width: 180px; padding: 1.3px">Catatan/Lainnya</td>
              <td>: {{ data.coordination_notes }}</td>
            </tr>
          </table>
        </div>
      </div>
      <!-- end coordination -->

       <!-- lab -->
      <div class="row mt-2">
         <div class="col-md-12">
          <span style="font-size: 16px;" class="font-weight-bold d-block mb-2">Kesimpulan</span>
          <span class="font-weight-bold d-block" >Pemeriksaan Labolatorium</span>
        </div>
       
        <div class="col-md-12 def-padding">
          <span v-html="data.lab" v-if="data.lab != null || data.lab != ''"></span>
          <span v-if="data.lab == null || data.lab == '' ">-</span>
        </div>
      </div>
      <!-- end lab-->
       
       <!-- lab -->
      <div class="row mt-2">
         <div class="col-md-12">
          <span class="font-weight-bold d-block" >Pemeriksaan Radiology</span>
        </div>
       
        <div class="col-md-12 def-padding">
          <span v-html="data.radiology" v-if="data.radiology != null || data.radiology != ''"></span>
          <span v-if="data.radiology == null || data.radiology == '' ">-</span>
        </div>
      </div>
      <!-- end lab-->

       <!-- ekg -->
      <div class="row mt-2">
         <div class="col-md-12">
          <span class="font-weight-bold d-block" >Pemeriksaan EKG</span>
        </div>
       
        <div class="col-md-12 def-padding">
          <span v-html="data.ekg" v-if="data.ekg != null || data.ekg != ''"></span>
          <span v-if="data.ekg == null || data.ekg == '' ">-</span>
        </div>
      </div>
      <!-- end ekg-->

       <!-- spirometry -->
      <div class="row mt-2">
         <div class="col-md-12">
          <span class="font-weight-bold d-block" >Pemeriksaan Spirometry</span>
        </div>
       
        <div class="col-md-12 def-padding">
          <span v-html="data.spirometry" v-if="data.spirometry != null || data.spirometry != ''"></span>
          <span v-if="data.spirometry == null || data.spirometry == '' ">-</span>
        </div>
      </div>
      <!-- end spirometry-->
     
       <!-- audiometry-->
      <div class="row mt-2">
         <div class="col-md-12">
          <span class="font-weight-bold d-block" >Pemeriksaan Audiometry</span>
        </div>
       
        <div class="col-md-12 def-padding">
          <span v-html="data.audiometry" v-if="data.audiometry!= null || data.audiometry!= ''"></span>
          <span v-if="data.audiometry== null || data.audiometry== '' ">-</span>
        </div>
      </div>
      <!-- end audiometry-->
       
       <!-- notes-->
      <div class="row mt-2">
         <div class="col-md-12">
          <span class="font-weight-bold d-block" >Catatan</span>
        </div>
       
        <div class="col-md-12 def-padding">
          <span v-html="data.notes" v-if="data.notes!= null || data.notes!= ''"></span>
          <span v-if="data.notes== null || data.notes== '' ">-</span>
        </div>
      </div>
      <!-- end notes-->
       
       <!-- summary-->
      <div class="row mt-2">
         <div class="col-md-12">
          <span class="font-weight-bold d-block" >Kesimpulan</span>
        </div>
       
        <div class="col-md-12 def-padding">
          <span v-html="data.summary" v-if="data.summary!= null || data.summary!= ''"></span>
          <span v-if="data.summary== null || data.summary== '' ">-</span>
        </div>
      </div>
      <!-- end summary-->
       
       <!-- suggestion-->
      <div class="row mt-2">
         <div class="col-md-12">
          <span class="font-weight-bold d-block" >Saran</span>
        </div>
       
        <div class="col-md-12 def-padding">
          <span v-html="data.suggestion" v-if="data.suggestion!= null || data.suggestion!= ''"></span>
          <span v-if="data.suggestion== null || data.suggestion== '' ">-</span>
        </div>
      </div>
      <!-- end suggestion-->

      <!-- <div class="w-100" :style="`height: ${height}`">
        <div
          class="editor-container mt-3"
          style="width: 100%"
          v-html="data.report"
        ></div>
      </div>
      <div class="d-flex justify-content-start mt-25">
        <div class="col-md-3">
          <span style="font-size: 12px; font-weight: light"
            >Jakarta, {{ data.display_date }}</span
          >
        </div>
      </div>
      <div class="d-flex justify-content-between mt-25 mb-0">
        <div class="col-md-7">
          <h5 style="font-size: 13px; font-weight: bold">
            
          </h5>
          <span>(Kepala Laboratorium)</span>
        </div>
        <div class="col-md-4">
          <span>Penyusun: {{ data.created_by_name }}</span>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import ImageComponent from "@/component/general/ImageComponent.vue";

export default {
  components: {
    ImageComponent,
  },

  data(){
    return{
      // lable
      health_history_label: {
        head_injury: "Cedera Kepala",
        lost_consciousness: "Hilang Kesadaran",
        tht_distrubance: "Gangguan THT > 4mg",
        sexual_distrubance: "Gangguan Alat Reproduksi",
        skin_distrubance: "Penyakit Kulit",
        diarrhea: "Diare Kronis",
        grastistis: "Maag (Grastistis)",
        heart_infection: "Infeksi Hati",
        hemoroid: "Hemoroid (Wasir)",
        diabetes: "Diabetes",
        tuberculosis: "Tuberculosis (TBC)",
        mental_distrubance: "Gangguan Kejiwaan",
        sexual_infection: "Penyakit Sexual",
        weight_lose: "Perubahan Berat Badan > 5kg",
        high_blood_pressure: "Tekanan Darah Tinggi",
        heart_disease: "Sakit Jantung",
        surgery_expirience: "Pernah Dioperasi",
        fever_typoid: "Demam Typoid",
        pain_at_joint: "Nyeri Pada Sendi",
        urinary_tract_disorders: "Kelainan Saluran Kencing",
        asma_bronkial: "Asma Bronkial",
        food_alergy: "Alergi Makanan",
        vertigo: "Pusing Berputar (vertigo)",
      },
      family_health_history_label: {
        father: "Ayah",
        mother: "Ibu",
        siblings: "Saudara Kandung",
      },
      working_history_label: {
        noise_vibration: "Kebisingan",
        temperature_over: "Suhu Ekstrim",
        smoke_dust: "Asap atau debu",
        chemical: "Kimia",
        big_machine: "Alat Berat",
      },
      habits_label: {
        smoking: "Merokok",
        drunk: "Minuman Keras",
        drugs: "Narkoba",
      },
      head_label: {
        hair: "Rambut",
      },
      ears_label: {
        ear_shape: "Bentuk Telinga",
        tympani_membran: "Membran Tympany",
        meatus_acusticus: "Meatus Acusticus",
      },
      nose_label: {
        nose_shape: "Bentuk Hidung",
        smelling: "Penciuman",
        mucosa_nose: "Mukosa Hidung",
        epistaksis: "Epistaksis",
      },
      throat_label: {
        tonsil_faring: "Tonsil & Faring",
        laring: "Laring / Pita Suara",
      },
      mounth_teeth_label: {
        mukosa_mounth: "Mukosa Mulut",
        tongue: "Lidah",
        teeth: "Gigi",
      },
      neck_label: {
        lympe_thyroid: "Kelenjar Lympe dan Gondok",
        jagular_venous_pressure: "Jagular Venous Pressure (JVP)",
      },
      thorax_label: {
        thorax_shape: "Bentuk Thorax",
        thorax_moving: "Pergerakan",
        lungs: "Paru-paru",
        heart_thorax: "Jantung",
        backbone: "Tulang Belakang",
      },
      abdomen_label: {
        usus_wall: "Dinding Usus",
        genitalia: "Genitalia",
        liver_and_spleen: "Hati & Limpa",
      },
      extremitas_label: {
        extremitas_top: "Extremitas Atas",
        extremitas_bottom: "Extremitas Bawah",
      },
      reflex_label: {
        physioligy: "Physioligy",
        phatology: "Phatology",
      },
      coordination_label: {
        teremor: "Tremor",
        tonus: "Tonus",
        paresis: "Paresis",
        paralysis: "Paralysis",
      },
    }
  },

  props: {
    data: Object,
    height: String,
    setting: Object,
    patient: Object,
  },

  methods: {
    btnDownloadOnClick() {
      this.$refs.html2Pdf.generatePdf();
    },

    btnPrintOnClick() {
      this.$htmlToPaper("print-lab-report");
    },
  },
};
</script>

<style>
figure.table table {
  width: 100% !important;
}
</style>

<style scoped>
.def-padding{
  padding-bottom: 1.5px;
  padding-top: 1.5px;
}
</style>
